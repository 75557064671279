import { useEffect, useState } from "react";
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import styles from "./scrollToTop.module.scss";

export const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 700) {
        setIsVisible(true);
      } else {
        return setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <BsFillArrowUpCircleFill
      className={`${
        isVisible ? styles.scrollToTopVisible : styles.scrollToTop
      }`}
      onClick={() => window.scrollTo(0, 0)}
    />
  );
};
