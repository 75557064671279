import React from "react";
import { useTranslation } from "react-i18next";
import logo from "../../assets/imgs/footerLogo.svg";
import { useSelector } from "react-redux";
import { fb, instagram, mail, map, tel } from "../../assets/svgs/svgs";
import { SiTiktok } from "react-icons/si";
import "./Footer.scss";

const Footer = () => {
  const { t } = useTranslation();
  const { admin } = useSelector((state) => state.home);

  const adminTel = admin?.phone?.tel1;

  return (
    <footer>
      <div className="contain">
        <div className="footer">
          <div className="footer_left">
            <img src={logo} alt="footer-logo" loading="lazy" />

            <div className="footer_left-info">
              <div className="footer_left-info-top">
                <a href={`tel:${adminTel}`}>
                  {tel.icon} {adminTel}
                </a>
                <a href="mailto:info@malibu.am">{mail.icon} info@malibu.am</a>
              </div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/search/Aram+64,+Boulevard+Plaza/@40.183189,44.5063797,905m/data=!3m1!1e3?entry=ttu&g_ep=EgoyMDI1MDIxOS4xIKXMDSoASAFQAw%3D%3D"
              >
                {map.icon} {t("contact_map")}
              </a>
            </div>
          </div>

          <div className="footer_right">
            <ul>
              <h4>{t("header_about")}</h4>
              <a href="#contact-us">{t("header_contact")}</a>
              {/* <Link to="about-us">{t("header_about")}</Link> */}
            </ul>

            <ul>
              <h4>{t("follow")}</h4>
              <div className="footer_right-social">
                <li>
                  <a
                    href="https://www.facebook.com/share/1BMdkmdTkv/?mibextid=wwXIfr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {fb.icon}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/malibu_estate?igsh=MXU2b2VpdWY4dzNheg=="
                    target="_blank"
                    rel="noreferrer"
                  >
                    {instagram.icon}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.tiktok.com/@malibu_estate?_t=ZS-8tYKrNIW4EJ&_r=1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SiTiktok />
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

// <ul>
//   <h4>{t("header_service")}</h4>
//   <li>
//     <Link to="services/buying_selling" className="footer_right-link">
//       {t("prop_sale")}
//     </Link>
//   </li>
//   <li>
//     <Link to="services/rental_in_yerevan" className="footer_right-link">
//       {t("prop_rent")}
//     </Link>
//   </li>
//   <li>
//     <Link to="services/property_management" className="footer_right-link">
//       {t("prop_management")}
//     </Link>
//   </li>
// </ul>;
