import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const HelmetAdmin = () => {
  const { pathname } = useLocation();

  let cleanPath = pathname.replace(/^\/?dashboard\//, "");

  function capitalize(str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
  }

  let newPath = capitalize(cleanPath);

  if (newPath !== "") {
    newPath = "| " + newPath;
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>Malibu {newPath}</title>
      </Helmet>
    </HelmetProvider>
  );
};

export default HelmetAdmin;
