import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import cookies from "js-cookie";
import baseApi from "../../services/api/baseApi";

const initialState = {
  language: cookies.get("i18next") || "am",
  exchange: cookies.get("exchange") || 1,
  exchangeValue: null,
  size: 1,
  userSearches: cookies.get("userSearches") || null,
  sale: [],
  rent: [],
  admin: null,
  transactionType: "",
  propertyType: [],
  buildingType: [],
  room: [],
  minPrice: "",
  maxPrice: "",
  minArea: "",
  maxArea: "",
  condition: [],

  searchData: null,
  burger: "close",
  openBurger: false,
};

// get top homes
export const getTopHomes = createAsyncThunk("home", async (language) => {
  try {
    const [saleData, rentData] = await Promise.all([
      baseApi.get(`/api/getSaleHomes/${language}`),
      baseApi.get(`/api/getRentHomes/${language}`),
    ]);

    return {
      sale: saleData.data,
      rent: rentData.data,
    };
  } catch (err) {
    console.log(`Get Top Sale/Rent Homes Error: ${err.message}`);
    throw err;
  }
});

// get exchange data
export const getExchange = createAsyncThunk("home/exchange", async () => {
  try {
    const { data } = await baseApi.get("/api/getExchange");
    return data;
  } catch (err) {
    console.log(`Get Exchange Data Error: ${err.message}`);
  }
});

// get admin data
export const getAdminData = createAsyncThunk("home/adminData", async () => {
  try {
    const { data } = await baseApi.get("/api/getGeneralAdmin");
    return data;
  } catch (err) {
    console.log(`Get Admin Data Error: ${err.message}`);
  }
});

// get search data
export const getSearchData = createAsyncThunk(
  "home/getSearchData",
  async (language) => {
    try {
      const { data } = await baseApi.get(
        `/api/getSearchAttributes/${language}`
      );
      return data;
    } catch (err) {
      console.log(`Get Search Data Error: ${err.message}`);
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setBurger: (state, action) => {
      state.burger = action.payload;
    },
    setOpenBurger: (state, action) => {
      state.openBurger = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setExchange: (state, action) => {
      state.exchange = action.payload;
    },
    setSize: (state, action) => {
      state.size = action.payload;
    },
    addTransactionType: (state, action) => {
      state.transactionType = action.payload;
    },
    addPropertyType: (state, action) => {
      state.propertyType = action.payload;
    },
    addBuildingType: (state, action) => {
      state.buildingType = action.payload;
    },
    addRooms: (state, action) => {
      state.room = action.payload;
    },
    addCondition: (state, action) => {
      state.condition = action.payload;
    },
    addMinPrice: (state, action) => {
      state.minPrice = action.payload;
    },
    addMaxPrice: (state, action) => {
      state.maxPrice = action.payload;
    },
    addMinArea: (state, action) => {
      state.minArea = action.payload;
    },
    addMaxArea: (state, action) => {
      state.maxArea = action.payload;
    },
    clearSearchCache: (state, action) => {
      state.transactionType = "";
      state.propertyType = [];
      state.buildingType = [];
      state.room = [];
      state.condition = [];
      state.minPrice = "";
      state.maxPrice = "";
      state.minArea = "";
      state.maxArea = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExchange.fulfilled, (state, action) => {
        state.exchangeValue = action.payload?.amount;
      })
      .addCase(getTopHomes.fulfilled, (state, action) => {
        state.sale = action.payload.sale;
        state.rent = action.payload.rent;
      })
      .addCase(getAdminData.fulfilled, (state, action) => {
        state.admin = action.payload;
      })
      .addCase(getSearchData.fulfilled, (state, action) => {
        state.searchData = action.payload;
      })
  },
});

export const {
  setBurger,
  setOpenBurger,
  setLanguage,
  setExchange,
  setSize,
  addTransactionType,
  addPropertyType,
  addBuildingType,
  addRooms,
  addMinPrice,
  addMaxPrice,
  addMinArea,
  addMaxArea,
  addCondition,
  clearSearchCache
} = homeSlice.actions;
export default homeSlice.reducer;
