import React, { Suspense } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../header/Header";
import { Loader } from "../loader/Loader";
import { ScrollToTop } from "../scrollToTop/scrollToTop";
import Footer from "../footer/Footer";

const Layout = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Header />
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
      {!pathname?.includes("result") && <Footer />}
      <ScrollToTop />
    </>
  );
};

export default Layout;
