export const headerExchange = [
  {
    id: 1,
    symbol: <p>USD</p>,
    value: "FaDollarSign",
  },
  {
    id: 2,
    symbol: <p>AMD</p>,
    value: "TbCurrencyDram",
  },
];
