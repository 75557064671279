import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { setBurger, setOpenBurger } from "../../store/slices/homeSlice";
import logo from "../../assets/imgs/logo.svg";
import Exchange from "./components/exchange/Exchange";
import Language from "./components/language/Language";
import { FaPhone } from "react-icons/fa6";
import "./Header.scss";

const Header = () => {
  const headerRef = useRef();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { language, burger, openBurger, admin } = useSelector(
    (state) => state.home
  );

  const contactCheck = pathname.includes("result") || /\/\d+$/.test(pathname);

  const adminTel = admin?.phone?.tel1;

  const mobile = useMediaQuery({ maxWidth: 768 });

  const handleBurger = () => {
    dispatch(setBurger(burger === "open" ? "close" : "open"));
    dispatch(setOpenBurger(!openBurger));
  };

  return (
    <header ref={headerRef} className="header">
      <div className="contain">
        <nav className="header__nav">
          <div className="header__left">
            <Link
              className="header__left-link"
              to={`/${language}`}
              onClick={() => window.scrollTo(0, 0)}
            >
              <img src={logo} alt="logo" />
            </Link>
          </div>

          <div
            className={`header__right ${
              openBurger ? "header__right-active" : ""
            }`}
          >
            <ul className="header__list">
              {!contactCheck && (
                <li>
                  <a
                    href="#contact-us"
                    className="header__link"
                    rel="noreferrer"
                    onClick={handleBurger}
                  >
                    {t("header_contact")}
                  </a>
                </li>
              )}
            </ul>
            <Exchange />
            <Language />
          </div>

          {mobile && (
            <div className="header__mobile">
              {adminTel && (
                <a href={`tel:${adminTel}`}>
                  <FaPhone />
                </a>
              )}

              <div
                className="header__burger"
                role="button"
                onClick={handleBurger}
              >
                <i className={burger}></i>
                <i className={burger}></i>
                <i className={burger}></i>
              </div>
            </div>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Header;

// const hrefClick = () => {
//   if (openBurger && burger === "open") {
//     dispatch(setBurger("close"));
//     dispatch(setOpenBurger(false));
//   }
// };

//  {paths?.map(({ id, name, to }) => {
//               const isActive = name === "header_service" && pathname.includes("/services/");

//               return (
//                 <li key={id}>
//                   <NavLink
//                     onClick={hrefClick}
//                     to={to}
//                     className={`header__link ${isActive ? "active" : ""}`}
//                   >
//                     {t(name)}
//                   </NavLink>
//                 </li>
//               );
//             })}
